import * as React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'

const IndexPageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
`

const BlogPostLink = styled(Link)`
  text-decoration: none;
  color: white;
`

const BlogPostSummary = styled.div`
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
  height: 260px;
  padding: 10px;
  margin: 10px;
  width: 260px;
`

const IndexPage = (props) => {
  console.log(props)
  return (
    <Layout>
      <IndexPageWrapper>
        {props.data.allBlogPost.edges.map(edge => (
          <BlogPostLink to={`/blogPost/${edge.node.id}`}>
          <BlogPostSummary key={edge.node.id}>
            <h2>{edge.node.title}</h2>
            <div>
              <p>
               {edge.node.excerpt}
              </p>
            </div>            
          </BlogPostSummary>
        </BlogPostLink>
        ))}
      </IndexPageWrapper>
    </Layout>
  )
}
  
export const query = graphql`
  {
    allBlogPost {
      edges {
        node {
          id
          title
          localImage {
            childImageSharp {
              fixed(width: 400) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          author {
            name
            title
          } 
          content
          excerpt
        }
      }
    }
  }
`

export default IndexPage
